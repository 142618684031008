import React from "react"
import { graphql, Link } from "gatsby"
import groupBy from "lodash/groupBy"
import scrollToElement from "scroll-to-element"
import Layout from "../components/layout"
import { getArtistPath } from "../utils/url"
import removeDiacritics from "../utils/removeDiacritics"
import SEO from "../components/seo"

export default ({ data }) => {
  const artists = data.allStrapiArtist.nodes.map(artist => {
    return {
      ...artist,
      plainName: removeDiacritics(artist.name),
    }
  })

  const artistsByFirstLetter = groupBy(artists, artist => artist.plainName[0])

  return (
    <Layout>
      <SEO title="Αρχείο Καλλιτεχνών" />
      <article>
        <h1 className="font-semibold font-sans text-gray-900 mb-6 text-3xl md:text-4xl leading-tight">
          Αρχείο Καλλιτεχνών
        </h1>
        <ul className="flex flex-wrap -m-1">
          {Object.keys(artistsByFirstLetter)
            .sort((a, b) => a.localeCompare(b))
            .map(firstLetter => {
              return (
                <li key={firstLetter} className="m-1">
                  <button
                    onClick={() => scrollToElement(`#${firstLetter}`)}
                    className="block w-6 h-6 text-center bg-gray-200 text-gray-600 hover:text-gray-900"
                  >
                    {firstLetter}
                  </button>
                </li>
              )
            })}
        </ul>
        <div className="mt-8">
          {Object.keys(artistsByFirstLetter)
            .sort((a, b) => a.localeCompare(b))
            .map(firstLetter => {
              return (
                <React.Fragment key={firstLetter}>
                  <h5
                    id={firstLetter}
                    className="mt-4 text-gray-600 font-semibold"
                  >
                    {firstLetter}
                  </h5>
                  <ul className="mb-4">
                    {artistsByFirstLetter[firstLetter]
                      .sort((firstArtist, secondArtist) =>
                        firstArtist.plainName.localeCompare(
                          secondArtist.plainName
                        )
                      )
                      .map(artist => {
                        return (
                          <li key={artist.id} className="my-2">
                            <Link
                              to={getArtistPath(artist)}
                              className="text-blue-700 hover:underline"
                            >
                              {artist.name}
                            </Link>
                          </li>
                        )
                      })}
                  </ul>
                </React.Fragment>
              )
            })}
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiArtist(sort: { fields: name, order: ASC }) {
      nodes {
        id: strapiId
        name
        songs {
          id
        }
        translations {
          id
        }
      }
    }
  }
`
